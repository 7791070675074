import { KsAppClientData, KsRenderResults } from '@knapsack/types';
import type {
  Except,
  GenericResponse,
  KsAppClientDataAndMeta,
  KsAppClientDataNoMeta,
  KsFileSaverParams,
} from '@knapsack/types';
import { fetcher } from '@knapsack/utils';
import type {
  PatternRenderData,
  KnapsackDataStoreSaveBody,
  EndpointPaths,
  EndpointFiles,
  ExtractEndpointData,
  ExtractEndpointResBody,
  EndpointPlugins,
} from '@knapsack/app/api-info';

export type { PatternRenderData, EndpointPaths };

export async function checkAppClientUrl(url: string): Promise<GenericResponse> {
  try {
    const theUrl = new URL('/api/v1' satisfies EndpointPaths, url); // will throw if url not formatted correctly
    const res = await fetcher<GenericResponse>({
      url: theUrl.toString(),
    });
    return res;
  } catch (err) {
    return {
      ok: false,
      message: err instanceof Error ? err.message : 'Unknown error',
    };
  }
}

export async function getDataStore({
  appClientUrl,
}: {
  appClientUrl: string;
}): Promise<GenericResponse<KsAppClientDataAndMeta>> {
  try {
    const data = await fetcher<KsAppClientDataAndMeta>({
      url: new URL(
        '/api/v1/data-store' satisfies EndpointPaths,
        appClientUrl,
      ).toString(),
    });

    return {
      ok: true,
      data,
    };
  } catch (err) {
    return {
      ok: false,
      message: err instanceof Error ? err.message : 'Unknown error',
    };
  }
}

// just need a function that can throw an error
export async function getAppClientData({
  appClientUrl,
}: {
  appClientUrl: string;
}): Promise<KsAppClientDataAndMeta> {
  const serverDataRes = await getDataStore({ appClientUrl });
  if (!serverDataRes.ok || !serverDataRes.data) {
    const message = `Could not load data from custom url "${appClientUrl}" ${serverDataRes.message}`;
    throw new Error(message);
  }
  return serverDataRes.data;
}

/** Sole job is to strip `metaState` if it's there */
export function prepAppClientData(
  state: KsAppClientData,
): KsAppClientDataNoMeta {
  const { metaState, ...appClientDataNoMeta } = state;
  return appClientDataNoMeta;
}

export async function prepareDataForFileSave({
  urlBase,
  state,
}: {
  urlBase: string;
  state: KsAppClientDataNoMeta;
}): Promise<GenericResponse<KsFileSaverParams>> {
  try {
    const body: KnapsackDataStoreSaveBody = {
      state: prepAppClientData(state),
    };
    const data = await fetcher<GenericResponse<KsFileSaverParams>>({
      url: new URL(
        '/api/v1/data-store-prep' satisfies EndpointPaths,
        urlBase,
      ).toString(),
      body,
    });

    return data;
  } catch (err) {
    return {
      ok: false,
      message: err instanceof Error ? err.message : 'Unknown error',
    };
  }
}

export async function submitDataForFileSave({
  urlBase,
  state,
}: {
  urlBase: string;
  state: KsAppClientDataNoMeta;
}): Promise<void> {
  const appClientData = prepAppClientData(state);
  const body: KnapsackDataStoreSaveBody = {
    state: appClientData,
  };
  const data = await fetcher<GenericResponse>({
    url: new URL(
      '/api/v1/data-store' satisfies EndpointPaths,
      urlBase,
    ).toString(),
    body,
  });

  if (!data.ok) {
    throw new Error(`Could not save data to localhost files: ${data.message}`);
  }
}

export async function getPluginContent({
  pluginId,
  appClientUrl,
}: {
  appClientUrl: string;
} & Except<ExtractEndpointData<EndpointPlugins>, 'type'>): Promise<
  ExtractEndpointResBody<EndpointPlugins>
> {
  return fetcher({
    url: new URL(
      '/api/v1/plugins' satisfies EndpointPlugins['path'],
      appClientUrl,
    ).toString(),
    body: {
      pluginId,
      type: 'getContent',
    } satisfies ExtractEndpointData<EndpointPlugins>,
  });
}

export async function renderTemplate({
  options,
  appClientUrl,
}: {
  options: PatternRenderData;
  appClientUrl: string;
}): Promise<KsRenderResults> {
  const data = await fetcher<KsRenderResults>({
    url: new URL(
      '/api/v1/render' satisfies EndpointPaths,
      appClientUrl,
    ).toString(),
    body: options,
  });
  return data;
}

export async function verifyFile({
  appClientUrl,
  payload,
}: {
  appClientUrl: string;
  payload: ExtractEndpointData<EndpointFiles>['payload'];
}) {
  const data = await fetcher<ExtractEndpointResBody<EndpointFiles>>({
    url: new URL(
      '/api/v1/files' satisfies EndpointFiles['path'],
      appClientUrl,
    ).toString(),
    body: {
      type: 'verify',
      payload,
    } satisfies ExtractEndpointData<EndpointFiles>,
  });
  return data.payload;
}
