import {
  // type structs validate that a value has a set of properties on it, but it does not assert anything about unspecified properties. This allows you to assert that a particular set of functionality exists without a strict equality check for properties.
  type,
  string,
  Describe,
  boolean,
  optional,
  array,
  number,
  define,
} from 'superstruct';
import { isObjKey, isObject } from '../type-utils';
import type { KnapsackAssetSetsData } from './asset-sets';
import type { KsDesignTokensState } from './design-tokens';
import type { MetaState } from './meta';
import type { KnapsackNavsConfig } from './nav';
import type { KsFiles } from './files';
import type { KnapsackCustomPagesData } from './custom-pages';
import type { PatternsState } from './patterns';
import type { KnapsackSettings } from './settings';

export type {
  KsDesignToken,
  KsDesignTokenAttributes,
  KsDesignTokenData,
  KsDesignTokenDataGroup,
  KsDesignTokenSrc,
  KsDesignTokenSrcGroup,
  KsDesignTokensState,
  KsTokenType,
  KsTokenValue,
  KnapsackDesignToken,
} from './design-tokens';

export interface KsAppClientDataV3 {
  settingsState: {
    settings: KnapsackSettings;
  };
  patternsState: PatternsState;
  customPagesState: KnapsackCustomPagesData;
  assetSetsState: KnapsackAssetSetsData;
  navsState: KnapsackNavsConfig;
  tokensState: KsDesignTokensState;
  metaState?: MetaState;
  filesState: KsFiles;
}

export type KsAppClientDataNoMetaV3 = Omit<KsAppClientDataV3, 'metaState'>;
export type KsAppClientDataAndMetaV3 = Required<KsAppClientDataV3>;

export function isKsAppClientDataNoMetaV3(
  data: unknown,
): data is KsAppClientDataNoMetaV3 {
  if (!isObject(data)) return false;
  const requiredKeys: Array<keyof KsAppClientDataNoMetaV3> = [
    'assetSetsState',
    'customPagesState',
    'navsState',
    'patternsState',
    'settingsState',
    'tokensState',
  ];
  if (!requiredKeys.every((key) => isObjKey(key, data))) return false;
  if (!isObjKey('metaState', data)) return true;
  return false;
}

export const KsAppClientDataNoMetaStructV3 = define<KsAppClientDataNoMetaV3>(
  'KsAppClientDataNoMeta',
  isKsAppClientDataNoMetaV3,
);

export function isKsAppClientDataAndMetaV3(
  data: unknown,
): data is KsAppClientDataAndMetaV3 {
  if (!isObject(data)) return false;
  const requiredKeys: Array<keyof KsAppClientDataAndMetaV3> = [
    'assetSetsState',
    'customPagesState',
    'navsState',
    'patternsState',
    'settingsState',
    'tokensState',
    'metaState',
  ];
  return requiredKeys.every((key) => isObjKey(key, data));
}

export const KsAppClientDataAndMetaStructV3 = define<KsAppClientDataAndMetaV3>(
  'KsAppClientDataAndMeta',
  isKsAppClientDataAndMetaV3,
);

export const KsMetaStateStructV3: Describe<KsAppClientDataV3['metaState']> =
  type({
    isLocalDev: boolean(),
    plugins: array(
      type({
        id: string(),
        hasContent: boolean(),
        clientPluginPath: optional(string()),
        cssPath: optional(string()),
      }),
    ),
    meta: optional(
      type({
        version: optional(string()),
        hasKnapsackCloud: boolean(),
        knapsackCloudSiteId: optional(string()),
        serverPort: optional(number()),
        websocketsPort: optional(number()),
        https: optional(boolean()),
        cacheDir: optional(string()),
        ksVersions: type({
          app: string(),
        }),
      }),
    ),
  });

export const isKsMetaStateV3 = KsMetaStateStructV3.is;
