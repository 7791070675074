import {
  createAppApiGqlClient,
  createAppApiRestClient,
} from '@knapsack/app-api-gql-client';
import { featureFlags } from '@/utils/feature-flags';
import { captureException } from '@/utils/sentry';
import { getHttpHeaders as getHeaders } from './http-headers-utils';

export * from '@knapsack/app-api-gql-client';

export const appApiUrl = new URL(
  featureFlags.appApiGqlEndpoint || 'https://api.knapsack.cloud',
).origin;

export const appApiGql = createAppApiGqlClient({
  serverUrl: appApiUrl,
  getHeaders,
  logError: (error) => captureException(error),
});

export const appApiRest = createAppApiRestClient({
  serverUrl: appApiUrl,
  getHeaders,
  logError: (error) => captureException(error),
});
