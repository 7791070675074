import {
  Describe,
  string,
  type,
  union,
  number,
  enums,
  optional,
  literal,
  array,
  size,
  boolean,
  object,
} from 'superstruct'; // https://docs.superstructjs.org
import { ColorConfig, ColorConfigStruct } from './color-config';

export const HeadingLevels = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] as const;
export type HeadingLevel = (typeof HeadingLevels)[number];

export type FontSizeConfigVal = {
  unit: 'px' | 'rem';
  value: number;
};

export const FontSizeConfigValStruct: Describe<FontSizeConfigVal> = object({
  unit: enums(['rem', 'px']),
  value: number(),
});

export type FontFaceWeight = number | string;

export const FontFaceWeightStruct: Describe<FontFaceWeight> = union([
  string(),
  number(),
]);

export enum FontFaceSourceFormats {
  woff2 = 'woff2',
  woff = 'woff',
}

export type FontFaceSource = {
  /**
   * Note: if /when we eventually add 'local' type support, format isn't used!
   */
  format: keyof typeof FontFaceSourceFormats;
  type: 'url';
  path: string;
};

export const FontFaceFormatsEnumStruct = enums(['woff2', 'woff']);

export const FontFaceSourceStruct: Describe<FontFaceSource> = type({
  format: FontFaceFormatsEnumStruct,
  type: literal('url'),
  path: string(),
});

export type FontFaceConfig = {
  /**
   * registered name of the custom font
   *
   * MDN Docs on {@link https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-family @font-face's font-family property}
   */
  family?: string;

  /**
   * number between 1 and 1000.
   *
   * note: since two weights are allowed (with variable fonts), this type is an
   * optional array for easy parsing down the road.
   *
   * MDN Docs on {@link https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-weight @font-face's font-weight property}.
   */
  weight?: FontFaceWeight | FontFaceWeight[];

  /**
   * the collection of urls / paths pointing to font files
   *
   * MDN Docs on {@link https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/src @font-face's src property}.
   */
  src?: FontFaceSource[];

  /**
   * the style of the custom font (ex. normal or italic)
   *
   * {@link https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-style See MDN docs on font-style}.
   */
  style?: string;

  // @todo: implement these later
  // display?: 'auto' | 'block' | 'swap' | 'fallback' | 'optional';
  // variationSettings?: string;
  // unicodeRange?: string; // or ideally, URange; //
  // see https://drafts.csswg.org/css-syntax-3/#urange-syntax and
  // https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/unicode-range
};

export const FontFaceConfigStruct: Describe<FontFaceConfig> = type({
  family: optional(string()),
  weight: optional(
    union([FontFaceWeightStruct, size(array(FontFaceWeightStruct), 2)]),
  ),
  src: optional(array(FontFaceSourceStruct)),
  style: optional(string()),
  display: optional(enums(['auto', 'block', 'swap', 'fallback', 'optional'])),
  variationSettings: optional(string()),
  unicodeRange: optional(string()),
});

export type FontSizeConfig = {
  min: FontSizeConfigVal;
  max?: FontSizeConfigVal;
};

export enum TextTransforms {
  inherit = 'As typed',
  capitalize = 'Capitalize',
  lowercase = 'Lowercase',
  uppercase = 'Uppercase',
}

export enum FontStretches {
  'ultra-condensed' = 'Ultra-condensed',
  'extra-condensed' = 'Extra-condensed',
  condensed = 'Condensed',
  'semi-condensed' = 'Semi-condensed',
  normal = 'Normal',
  'semi-expanded' = 'Semi-expanded',
  expanded = 'Expanded',
  'extra-expanded' = 'Extra-expanded',
  'ultra-expanded' = 'Ultra-expanded',
}

export type TypographyConfig = {
  /**
   * min / max height used to future-proof way of supporting responsive typography
   */
  fontSize?: FontSizeConfig;

  /**
   * reminder: line-heights can also be unitless!
   */
  lineHeight?: string | number;
  fontWeight?: FontFaceWeight;

  /**
   * note: letter-spacing can't be unitless, unit required!
   */
  letterSpacing?: string;

  /**
   * if not specified, Knapsack default font, otherwise should allow for a stack of font strings + fallbacks (including the names of custom registered fonts)
   */
  fontFamily?: string;

  textColor?: ColorConfig;

  textTransform?: keyof typeof TextTransforms;

  fontStretch?: keyof typeof FontStretches;
};

export interface TypographyLinkConfig {
  fontWeight?: FontFaceWeight;
  textColor?: ColorConfig;
  hoverColor?: ColorConfig;
  underline?: boolean;
  textTransform?: keyof typeof TextTransforms;
  fontStretch?: keyof typeof FontStretches;
}

export const TypographyLinkConfigStruct: Describe<TypographyLinkConfig> = type({
  fontWeight: optional(union([string(), number()])),
  textColor: optional(ColorConfigStruct),
  hoverColor: optional(ColorConfigStruct),
  underline: optional(boolean()),
  textTransform: optional(
    enums(['inherit', 'capitalize', 'lowercase', 'uppercase']),
  ),
  fontStretch: optional(
    enums([
      'ultra-condensed',
      'extra-condensed',
      'condensed',
      'semi-condensed',
      'normal',
      'semi-expanded',
      'expanded',
      'extra-expanded',
      'ultra-expanded',
    ]),
  ),
});

export const FontSizeConfigStruct: Describe<FontSizeConfig> = type({
  min: FontSizeConfigValStruct,
  max: optional(FontSizeConfigValStruct),
});

export const TypographyConfigStruct: Describe<TypographyConfig> = type({
  fontSize: optional(FontSizeConfigStruct),
  lineHeight: optional(union([string(), number()])),
  fontWeight: optional(union([string(), number()])),
  letterSpacing: optional(string()),
  fontFamily: optional(string()),
  textColor: optional(ColorConfigStruct),
  textTransform: optional(
    enums(['inherit', 'capitalize', 'lowercase', 'uppercase']),
  ),
  fontStretch: optional(
    enums([
      'ultra-condensed',
      'extra-condensed',
      'condensed',
      'semi-condensed',
      'normal',
      'semi-expanded',
      'expanded',
      'extra-expanded',
      'ultra-expanded',
    ]),
  ),
});

const pageElementConfig: TypographyConfig = {};
const pageLinkElementConfig: TypographyLinkConfig = {};

export const pageElements = {
  pageTitle: pageElementConfig,
  pageDescription: pageElementConfig,
  headingLarge: pageElementConfig,
  headingMedium: pageElementConfig,
  headingSmall: pageElementConfig,
  bodyText: pageElementConfig,
  link: pageLinkElementConfig,
} as const;

export const pageElementsV2 = {
  pageTitle: pageElementConfig,
  pageDescription: pageElementConfig,
  headingXLarge: pageElementConfig,
  headingLarge: pageElementConfig,
  headingMedium: pageElementConfig,
  headingSmall: pageElementConfig,
  headingXSmall: pageElementConfig,
  bodyText: pageElementConfig,
  link: pageLinkElementConfig,
} as const;

export type PageElementNames = keyof typeof pageElementsV2;
export type PageElementConfig = (typeof pageElementsV2)[PageElementNames];
