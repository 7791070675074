import { KsAppClientDataAndMeta } from '@knapsack/types';

export type SiteDeployedWebhookBody = {
  siteId: string;
  appClientData: KsAppClientDataAndMeta;
};

export const siteDeployedWebhookEndpoint = '/site-deployed';

export const gitHubOauthInternalEndpoint = '/github-auth';
