import { Simplify, expectType } from '@knapsack/utils';
import {
  TokenType,
  TokenDefinitionSingular,
  TokenDefinitionComposite,
  isTokenTypeComposite,
  isTokenTypeSingular,
} from './token-definitions';

type CreateTokenSrcValueInfo<
  T extends {
    type: TokenType;
    value: unknown;
  },
> =
  | {
      kind: 'ref';
      /** Has `{}` removed */
      referencedTokenId: string;
      isVarRef?: boolean;
      type: T['type'];
    }
  | {
      kind: 'static';
      type: T['type'];
      value: T['value'];
    };

type TokenSrcValueInfoSingularRaw = {
  // Ignore the key, pay attention to the value
  // The values become the items in the discriminated union
  [TheTokenType in TokenDefinitionSingular as TheTokenType['type']]: Simplify<
    CreateTokenSrcValueInfo<TheTokenType>
  >;
}[TokenDefinitionSingular['type']];

// This helper allows us to just pass the type string in to get that
// item out of the discriminated union: `TokenSrcValueInfoSingular<'color'>`
type TokenSrcValueInfoSingular<
  TT extends TokenSrcValueInfoSingularRaw['type'] = TokenSrcValueInfoSingularRaw['type'],
> = Extract<TokenSrcValueInfoSingularRaw, { type: TT }>;

expectType<TokenSrcValueInfoSingular<'dimension'>[]>([
  {
    type: 'dimension',
    kind: 'static',
    value: '1px',
  },
  {
    type: 'dimension',
    kind: 'ref',
    referencedTokenId: 'a.b',
  },
]);

type CreateSrcValueInfoForComposite<
  ValueObj extends Record<string, { type: TokenDefinitionSingular['type'] }>,
> = {
  [Key in keyof ValueObj]: Simplify<
    CreateTokenSrcValueInfo<{
      type: ValueObj[Key]['type'];
      value: TokenDefinitionSingular<ValueObj[Key]['type']>['value'];
    }>
  >;
};

type TokenSrcValueInfoCompositeRaw = {
  [TheTokenType in TokenDefinitionComposite['type']]: Simplify<
    CreateTokenSrcValueInfo<{
      type: TheTokenType;
      value: Simplify<
        CreateSrcValueInfoForComposite<
          TokenDefinitionComposite<TheTokenType>['value']
        >
      >;
    }>
  >;
}[TokenDefinitionComposite['type']];

type TokenSrcValueInfoComposite<
  TT extends TokenSrcValueInfoCompositeRaw['type'] = TokenSrcValueInfoCompositeRaw['type'],
> = Extract<TokenSrcValueInfoCompositeRaw, { type: TT }>;

expectType<TokenSrcValueInfoComposite<'border'>[]>([
  {
    type: 'border',
    kind: 'ref',
    referencedTokenId: 'a.b',
  },
  {
    type: 'border',
    kind: 'static',
    value: {
      color: {
        kind: 'static',
        type: 'color',
        value: 'red',
      },
      style: {
        kind: 'ref',
        type: 'strokeStyle',
        referencedTokenId: '', // FIX
      },
      width: {
        kind: 'static',
        type: 'dimension',
        value: '2px',
      },
    },
  },
]);

expectType<TokenSrcValueInfoComposite<'transition'>[]>([
  {
    type: 'transition',
    kind: 'ref',
    referencedTokenId: 'a.b',
  },
  {
    type: 'transition',
    kind: 'static',
    value: {
      delay: {
        kind: 'static',
        type: 'duration',
        value: '1ms',
      },
      duration: {
        kind: 'ref',
        type: 'duration',
        referencedTokenId: 'animation.slow',
      },
      timingFunction: {
        kind: 'static',
        type: 'cubicBezier',
        value: [0.4, 0.0, 0.2, 1],
      },
    },
  },
]);

export type TokenSrcValueInfo<
  TT extends
    | TokenSrcValueInfoComposite['type']
    | TokenSrcValueInfoSingular['type'] =
    | TokenSrcValueInfoComposite['type']
    | TokenSrcValueInfoSingular['type'],
  TheKind extends
    | TokenSrcValueInfoComposite['kind']
    | TokenSrcValueInfoSingular['kind'] =
    | TokenSrcValueInfoComposite['kind']
    | TokenSrcValueInfoSingular['kind'],
> = Extract<
  TokenSrcValueInfoComposite | TokenSrcValueInfoSingular,
  { type: TT; kind: TheKind }
>;

export function isTokenSrcValueInfoSingular(
  tokenSrcValueInfo: TokenSrcValueInfo,
): tokenSrcValueInfo is TokenSrcValueInfoSingular {
  return isTokenTypeSingular(tokenSrcValueInfo?.type);
}
export function isTokenSrcValueInfoComposite(
  tokenSrcValueInfo: TokenSrcValueInfo,
): tokenSrcValueInfo is TokenSrcValueInfoComposite {
  return isTokenTypeComposite(tokenSrcValueInfo?.type);
}

expectType<TokenSrcValueInfo[]>([
  {
    type: 'dimension',
    kind: 'static',
    value: '1px',
  },
  {
    type: 'transition',
    kind: 'ref',
    referencedTokenId: 'a.b',
  },
  {
    type: 'transition',
    kind: 'ref',
    // @ts-expect-error - with `kind: 'ref'`, `value` is not allowed
    value: '1px',
  },
  {
    kind: 'ref',
    type: 'duration',
    referencedTokenId: 'animation.slow',
  },
  {
    kind: 'static',
    type: 'cubicBezier',
    value: [0.4, 0.0, 0.2, 1],
  },
  {
    type: 'transition',
    kind: 'static',
    value: {
      delay: {
        kind: 'static',
        type: 'duration',
        value: '1ms',
      },
      duration: {
        kind: 'ref',
        type: 'duration',
        referencedTokenId: 'animation.slow',
      },
      timingFunction: {
        kind: 'static',
        type: 'cubicBezier',
        value: [0.4, 0.0, 0.2, 1],
      },
    },
  },
]);

/**
 * A common object that is used to represent a token in the app.
 * Used in functions for editing communication between UI forms, Xstate,
 * and `@knapsack/design-tokens-utils`.
 */
export type TokenInfo<
  TheTokenType extends TokenSrcValueInfo['type'] = TokenSrcValueInfo['type'],
> = {
  tokenSrcValueInfo: TokenSrcValueInfo<TheTokenType>;
  tokenId: string;
  description?: string;
};
