import {
  type,
  Describe,
  literal,
  optional,
  defaulted,
  boolean,
  array,
  object,
} from 'superstruct';

import { ExpandRecursively } from '@knapsack/utils';

import {
  ColorConfig,
  ColorConfigStruct,
  ColorStringStruct,
} from './color-config';

import {
  FontFaceConfigStruct,
  FontFaceConfig,
  TypographyConfigStruct,
  TypographyLinkConfigStruct,
  pageElements,
  pageElementsV2,
} from './typography-config';

import {
  customizableNavElements,
  NavCustomizationConfigStruct,
  PrimaryNavCustomizationConfigStruct,
  SecondaryNavCustomizationConfigStruct,
} from './nav-customization-config';

export type UiConfigV1 = {
  version: 1;
  oldSillySidebarBgColor: string;
};

export const UiConfigV1Struct: Describe<UiConfigV1> = object({
  version: literal(1),
  oldSillySidebarBgColor: ColorStringStruct,
});

export type UiConfigV2 = {
  version: 2;
  anotherSillySidebarBgColor: string;
};

export const UiConfigV2Struct: Describe<UiConfigV2> = object({
  version: literal(2),
  anotherSillySidebarBgColor: ColorStringStruct,
});

export type UiConfigV3 = {
  version: 3;
  sidebarBgColor: string;
};

export const UiConfigV3Struct: Describe<UiConfigV3> = object({
  version: literal(3),
  sidebarBgColor: ColorStringStruct,
});

export type UiConfigV4 = {
  version: 4;
  primaryColor: ColorConfig;
  darkColor: ColorConfig;
  lightColor: ColorConfig;
  enableSidebarIcons?: boolean;
};
export const UiConfigV4Struct: Describe<UiConfigV4> = object({
  version: literal(4),
  primaryColor: ColorConfigStruct,
  darkColor: ColorConfigStruct,
  lightColor: ColorConfigStruct,
  enableSidebarIcons: optional(defaulted(boolean(), true)),
});

export type UiConfigV5 = {
  version: 5;
  primaryColor: ColorConfig;
  darkColor: ColorConfig;
  lightColor: ColorConfig;
  linkColor: ColorConfig;
  enableSidebarIcons: boolean;
};
export const UiConfigV5Struct: Describe<UiConfigV5> = object({
  version: literal(5),
  primaryColor: ColorConfigStruct,
  darkColor: ColorConfigStruct,
  lightColor: ColorConfigStruct,
  linkColor: ColorConfigStruct,
  enableSidebarIcons: optional(defaulted(boolean(), true)),
});

export interface _UiConfigV6 extends Omit<UiConfigV5, 'version'> {
  version: 6;
  appearance: {
    customFonts?: FontFaceConfig[];
    pageElements: typeof pageElements;
  };
}

// ExpandRecursively needed to avoid "Type 'UiConfigV6' is not assignable to type 'Record<string, unknown>'." error
export type UiConfigV6 = ExpandRecursively<_UiConfigV6>;

export const UiConfigV6Struct: Describe<UiConfigV6> = object({
  version: literal(6),
  primaryColor: ColorConfigStruct,
  darkColor: ColorConfigStruct,
  lightColor: ColorConfigStruct,
  linkColor: ColorConfigStruct,
  enableSidebarIcons: optional(defaulted(boolean(), true)),

  appearance: type({
    customFonts: optional(array(FontFaceConfigStruct)),
    pageElements: type({
      pageTitle: optional(TypographyConfigStruct),
      pageDescription: optional(TypographyConfigStruct),
      headingLarge: optional(TypographyConfigStruct),
      headingMedium: optional(TypographyConfigStruct),
      headingSmall: optional(TypographyConfigStruct),
      bodyText: optional(TypographyConfigStruct),
      link: optional(TypographyLinkConfigStruct),
    }),
  }),
});

export interface _UiConfigV7 extends Omit<UiConfigV6, 'version'> {
  version: 7;
  appearance: {
    navigation?: typeof customizableNavElements;
    customFonts?: UiConfigV6['appearance']['customFonts'];
    pageElements: UiConfigV6['appearance']['pageElements'];
  };
}

// ExpandRecursively needed to avoid "Type 'UiConfig7' is not assignable to type 'Record<string, unknown>'." error in ui-config.xstate.ts
export type UiConfigV7 = ExpandRecursively<_UiConfigV7>;

export const UiConfigV7Struct: Describe<UiConfigV7> = object({
  version: literal(7),
  primaryColor: ColorConfigStruct,
  darkColor: ColorConfigStruct,
  lightColor: ColorConfigStruct,
  linkColor: ColorConfigStruct,
  enableSidebarIcons: optional(defaulted(boolean(), true)),

  appearance: type({
    customFonts: optional(array(FontFaceConfigStruct)),
    navigation: optional(
      type({
        primaryNav: optional(PrimaryNavCustomizationConfigStruct),
        secondaryNav: optional(SecondaryNavCustomizationConfigStruct),
        tabs: optional(NavCustomizationConfigStruct),
      }),
    ),
    pageElements: type({
      pageTitle: optional(TypographyConfigStruct),
      pageDescription: optional(TypographyConfigStruct),
      headingLarge: optional(TypographyConfigStruct),
      headingMedium: optional(TypographyConfigStruct),
      headingSmall: optional(TypographyConfigStruct),
      bodyText: optional(TypographyConfigStruct),
      link: optional(TypographyLinkConfigStruct),
    }),
  }),
});

export interface _UiConfigV8
  extends Omit<UiConfigV7, 'version' | 'enableSidebarIcons'> {
  version: 8;
}

// ExpandRecursively needed to avoid "Type 'UiConfig8' is not assignable to type 'Record<string, unknown>'." error in ui-config.xstate.ts
// TODO: move this to @knapsack/types
export type UiConfigV8 = ExpandRecursively<_UiConfigV8>;

export const UiConfigV8Struct: Describe<UiConfigV8> = object({
  version: literal(8),
  primaryColor: ColorConfigStruct,
  darkColor: ColorConfigStruct,
  lightColor: ColorConfigStruct,
  linkColor: ColorConfigStruct,

  appearance: type({
    customFonts: optional(array(FontFaceConfigStruct)),
    navigation: optional(
      type({
        primaryNav: optional(PrimaryNavCustomizationConfigStruct),
        secondaryNav: optional(SecondaryNavCustomizationConfigStruct),
        tabs: optional(NavCustomizationConfigStruct),
      }),
    ),
    pageElements: type({
      pageTitle: optional(TypographyConfigStruct),
      pageDescription: optional(TypographyConfigStruct),
      headingLarge: optional(TypographyConfigStruct),
      headingMedium: optional(TypographyConfigStruct),
      headingSmall: optional(TypographyConfigStruct),
      bodyText: optional(TypographyConfigStruct),
      link: optional(TypographyLinkConfigStruct),
    }),
  }),
});

export interface _UiConfigV9
  extends Omit<
    UiConfigV8,
    'version' | 'primaryColor' | 'darkColor' | 'lightColor' | 'linkColor'
  > {
  version: 9;
}
export type UiConfigV9 = ExpandRecursively<_UiConfigV9>;

export const UiConfigV9Struct: Describe<UiConfigV9> = object({
  version: literal(9),

  appearance: type({
    customFonts: optional(array(FontFaceConfigStruct)),
    navigation: optional(
      type({
        primaryNav: optional(PrimaryNavCustomizationConfigStruct),
        secondaryNav: optional(SecondaryNavCustomizationConfigStruct),
        tabs: optional(NavCustomizationConfigStruct),
      }),
    ),
    pageElements: type({
      pageTitle: optional(TypographyConfigStruct),
      pageDescription: optional(TypographyConfigStruct),
      headingLarge: optional(TypographyConfigStruct),
      headingMedium: optional(TypographyConfigStruct),
      headingSmall: optional(TypographyConfigStruct),
      bodyText: optional(TypographyConfigStruct),
      link: optional(TypographyLinkConfigStruct),
    }),
  }),
});

export interface _UiConfigV10 extends Omit<UiConfigV9, 'version'> {
  version: 10;
  appearance: {
    navigation?: UiConfigV9['appearance']['navigation'];
    customFonts?: UiConfigV9['appearance']['customFonts'];
    pageElements: typeof pageElementsV2;
  };
}
export type UiConfigV10 = ExpandRecursively<_UiConfigV10>;

export const UiConfigV10Struct: Describe<UiConfigV10> = object({
  version: literal(10),

  appearance: type({
    customFonts: optional(array(FontFaceConfigStruct)),
    navigation: optional(
      type({
        primaryNav: optional(PrimaryNavCustomizationConfigStruct),
        secondaryNav: optional(SecondaryNavCustomizationConfigStruct),
        tabs: optional(NavCustomizationConfigStruct),
      }),
    ),
    pageElements: type({
      pageTitle: optional(TypographyConfigStruct),
      pageDescription: optional(TypographyConfigStruct),
      headingXLarge: optional(TypographyConfigStruct),
      headingLarge: optional(TypographyConfigStruct),
      headingMedium: optional(TypographyConfigStruct),
      headingSmall: optional(TypographyConfigStruct),
      headingXSmall: optional(TypographyConfigStruct),
      bodyText: optional(TypographyConfigStruct),
      link: optional(TypographyLinkConfigStruct),
    }),
  }),
});

export type UiConfigVersions =
  | typeof UiConfigV1Struct.TYPE
  | typeof UiConfigV2Struct.TYPE
  | typeof UiConfigV3Struct.TYPE
  | typeof UiConfigV4Struct.TYPE
  | typeof UiConfigV5Struct.TYPE
  | typeof UiConfigV6Struct.TYPE
  | typeof UiConfigV7Struct.TYPE
  | typeof UiConfigV8Struct.TYPE
  | typeof UiConfigV9Struct.TYPE
  | typeof UiConfigV10Struct.TYPE;

// ALIASES FOR LATEST VERSIONS
export type UiConfig = UiConfigV10;
export const UiConfigStruct: typeof UiConfigV10Struct = UiConfigV10Struct;

export type NavElementNames = keyof UiConfig['appearance']['navigation'];
