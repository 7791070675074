'use client';

import type { KsAppClientDataNoMeta } from '@/types';
import type { PluginStore } from '@/domains/plugins';

const win = typeof window !== 'undefined' ? window : global;

/**
 * found at `window.knapsack`
 */
export interface KnapsackGlobal {
  /** Get AppClientData */
  getState: () => KsAppClientDataNoMeta;
  dispatch: typeof import('@/core').sendAppClientDataEvent;
  pluginStore?: PluginStore;
  /** Xstate */
  appService: typeof import('@/core').appService;
  appClientDataService: typeof import('@/core').appClientDataService;
  uiService: typeof import('@/core').uiService;
  isReady: boolean;
  goToUrl: (url: string) => void;
}

export interface WinWithKsGlobal extends Window {
  knapsackGlobal: KnapsackGlobal;
}

export function winHasKsGlobal(wind: Window): wind is WinWithKsGlobal {
  if ('knapsackGlobal' in wind) {
    return true;
  }
  return false;
}

if (!(win as any).knapsackGlobal) {
  (win as any).knapsackGlobal = {
    isReady: false,
  };
}

export const knapsackGlobal = (win as any).knapsackGlobal as KnapsackGlobal;
