import { parseNumberWithUnit } from '@knapsack/utils';

export function getPrimitiveValue(
  tokenValue: unknown,
): 'string' | 'number' | 'boolean' {
  const type = typeof tokenValue;
  if (type === 'string' || type === 'number' || type === 'boolean') {
    return type;
  }
  throw new Error(
    `Invalid token value type: ${JSON.stringify(
      type,
    )}. Token value is: ${JSON.stringify(tokenValue)}`,
  );
}

export type TokenDimensionUnit = 'px' | 'rem' | 'em' | '%';

/**
 * Dimension strings require ending in 'px' or 'rem'
 * @see `libs/types/src/app-client-data/design-tokens-types/token-definitions.ts`
 */
export const isDimensionString = (
  value: string,
): value is `${number}${'px' | 'rem' | 'em' | '%'}` =>
  value.includes('px') ||
  value.includes('rem') ||
  value.includes('em') ||
  value.includes('%');

/**
 * Pass in values like `'12px'` to get detailed info
 * @throws if value is not a number or does not end in supported unit
 */
export function parseTokenDimensionValue(valueToParse: string | number): {
  value: number;
  unit?: TokenDimensionUnit;
} {
  const { value, unit } = parseNumberWithUnit(valueToParse);
  if (!unit) {
    return { value };
  }
  if (unit !== 'px' && unit !== 'rem' && unit !== 'em' && unit !== '%') {
    throw new Error(`Invalid unit "${unit}" for value: ${value}`);
  }

  return { value, unit };
}
