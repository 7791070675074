import { Describe, type, union, optional } from 'superstruct'; // https://docs.superstructjs.org
import { ColorConfig, ColorConfigStruct } from './color-config';
import { TypographyConfig, TypographyConfigStruct } from './typography-config';

export interface NavCustomizationConfig extends TypographyConfig {
  accentColor?: ColorConfig;
}

export interface PrimaryNavCustomizationConfig extends NavCustomizationConfig {
  hoverColor?: ColorConfig;
  backgroundColor?: ColorConfig;
}

export type SecondaryNavCustomizationConfig = Omit<
  PrimaryNavCustomizationConfig,
  'hoverColor'
>;

export const NavCustomizationConfigStruct: Describe<NavCustomizationConfig> =
  union([
    TypographyConfigStruct,
    type({
      accentColor: optional(ColorConfigStruct),
    }),
  ]);

// hoverColor: optional(ColorConfigStruct),
export const PrimaryNavCustomizationConfigStruct: Describe<PrimaryNavCustomizationConfig> =
  union([
    NavCustomizationConfigStruct,
    type({
      hoverColor: optional(ColorConfigStruct),
      backgroundColor: optional(ColorConfigStruct),
    }),
  ]);

export const SecondaryNavCustomizationConfigStruct: Describe<SecondaryNavCustomizationConfig> =
  union([
    NavCustomizationConfigStruct,
    type({
      backgroundColor: optional(ColorConfigStruct),
    }),
  ]);

const navCustomizationConfig: NavCustomizationConfig = {};
const secondaryNavCustomizationConfig: SecondaryNavCustomizationConfig = {};
const primaryNavCustomizationConfig: PrimaryNavCustomizationConfig = {};

export const customizableNavElements = {
  primaryNav: primaryNavCustomizationConfig,
  secondaryNav: secondaryNavCustomizationConfig,
  tabs: navCustomizationConfig,
} as const;

export type CustomizableNavElementNames = keyof typeof customizableNavElements;
export type CustomizableNavElementConfig =
  (typeof customizableNavElements)[CustomizableNavElementNames];
