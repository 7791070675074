import colorString from 'color-string';
import { define, Describe, literal, string, type, union } from 'superstruct'; // https://docs.superstructjs.org

/** Use `import { isColor } from '@knapsack/color-utils'` if you can, need to duplicate this logic due to dep cycle */
function isColor(color: unknown): color is string {
  if (typeof color !== 'string') return false;
  try {
    return !!colorString.get(color);
  } catch (e) {
    return false;
  }
}

export type ColorTokenConfig = {
  type: 'design-token';
  /** Will be **either** `-` (old) or `.` (new) separated */
  tokenName: string;
};
export type ColorConfig =
  | { type: 'value'; colorValue: string }
  | ColorTokenConfig;

export const ColorStringStruct = define<string>(
  'ColorStringStruct',
  (value) => typeof value === 'string' && isColor(value),
);

export const ColorConfigStruct: Describe<ColorConfig> = union([
  type({
    type: literal('value'),
    colorValue: ColorStringStruct,
  }),
  type({
    type: literal('design-token'),
    tokenName: string(),
  }),
]);

// so we can check if an object is not just a ColorConfig but specifically a token
export const ColorTokenConfigStruct: Describe<ColorTokenConfig> = type({
  type: literal('design-token'),
  tokenName: string(),
});
