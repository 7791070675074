import { isUrl } from '@knapsack/utils';

const { KS_DB_URL } = process.env;
if (KS_DB_URL && !isUrl(KS_DB_URL)) {
  throw new Error(`KS_DB_URL is not a valid URL: '${KS_DB_URL}'`);
}
const dbUrl = KS_DB_URL || 'https://db.knapsack.cloud';
const dbGqlEndpoint = new URL('/v1/graphql', dbUrl).toString();

export const ksUrls = {
  /**
   * Base URL for Knapsack DB (Hasura)
   * @default 'https://db.knapsack.cloud'
   */
  dbUrl,
  /**
   * App DB GraphQL endpoint for connecting to Hasura GraphQL.
   * @default 'https://db.knapsack.cloud/v1/graphql'
   */
  dbGqlEndpoint,
};
