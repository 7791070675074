import * as s from 'superstruct';
import type { Patch } from 'immer';
import type { SetOptional } from '@knapsack/utils';
import type { UUID, DateString } from './misc';

/**
 * Patch
 */

export type { Patch };

const PatchStructRuntime = s.object({
  op: s.union([s.literal('replace'), s.literal('remove'), s.literal('add')]),
  path: s.array(s.union([s.string(), s.number()])),
  value: s.optional(s.any()),
});
const PatchStruct: s.Describe<Patch> = PatchStructRuntime;

export const isPatch = (patch: unknown): patch is Patch =>
  PatchStruct.is(patch);

const PatchesStructRuntime = s.array(PatchStructRuntime);
const PatchesStruct: s.Describe<Patch[]> = PatchesStructRuntime;

export const isPatches = (patches: unknown): patches is Patch[] =>
  PatchesStruct.is(patches);

/**
 * KsRemoteChange
 */
export type KsChange<Event extends { type: string } = { type: string }> = {
  patches: Patch[];
  inversePatches: Patch[];
  event: Event;
  id: UUID;
  date: DateString;
};
export type KsRemoteChange = SetOptional<KsChange, 'inversePatches' | 'event'>;

const KsRemoteChangeRuntime = s.type({
  patches: PatchesStructRuntime,
  inversePatches: s.optional(PatchesStructRuntime),
  event: s.optional(s.object({ type: s.string() })),
  id: s.string(),
  date: s.string(),
  userId: s.optional(s.string()),
});
const KsRemoteChangeStruct: s.Describe<KsRemoteChange> = KsRemoteChangeRuntime;

export const isKsRemoteChange = (change: unknown): change is KsRemoteChange =>
  KsRemoteChangeStruct.is(change);

const KsRemoteChangesStructRuntime = s.array(KsRemoteChangeRuntime);
export const KsRemoteChangesStruct: s.Describe<KsRemoteChange[]> =
  KsRemoteChangesStructRuntime;

export const isKsRemoteChanges = (
  changes: unknown,
): changes is KsRemoteChange[] => KsRemoteChangesStruct.is(changes);
