import { isDraft, type Draft } from 'immer';
import { Migration } from './migration.types';
import { KnapsackPattern, SlottedData } from '../app-client-data/patterns';

type Migration417DemosType = {
  version: string;
  checkIfNeeded: (appClientData: any) => boolean;
  migrate: (appClientData: Draft<any>) => void;
};

export const migration417Demos: Migration417DemosType = {
  version: '4.1.7',
  checkIfNeeded: (appClientData) => {
    // this one will be tough to check for, so we'll just assume it's needed
    return true;
  },
  // note that `appClientData` is mutable since we're already in Immer
  migrate: (appClientData) => {
    if (!isDraft(appClientData)) {
      throw new Error(
        `Tried to run migrations on appClientData but it is not an Immer Draft`,
      );
    }
    Object.values(appClientData.patternsState.patterns).forEach(
      (
        pattern: Draft<
          KnapsackPattern & {
            demoSize?: string;
          }
        >,
      ) => {
        delete pattern.demoSize;
      },
    );

    Object.values(appClientData.db.demos.byId).forEach((demo) => {
      if (demo.type !== 'data') return;

      Object.entries(demo.data.slots ?? {}).forEach(([slotName, slotData]) => {
        demo.data.slots[slotName] = slotData.map(
          (slottedData: Draft<SlottedData | string>) => {
            // it used to be just a string
            if (typeof slottedData === 'string') {
              return {
                type: 'text',
                text: slottedData,
              };
            }
            if (
              // not doing anything with these
              slottedData.type === 'template-reference' ||
              // these already were migrated or made by the new editor
              slottedData.type === 'text'
            ) {
              return slottedData;
            }
            // these used to not have a type set
            slottedData.type = 'template-demo';
            return slottedData;
          },
        );
      });

      if (demo.patternId && demo.templateId) return;

      // using an IIFE so we can stop looping once we find it
      // would use `break` but it's a double loop
      (() => {
        for (const { id: pId, templates = [] } of Object.values(
          appClientData.patternsState.patterns,
        )) {
          for (const { id: tId, demoIds = [] } of templates) {
            if (demoIds.includes(demo.id)) {
              demo.patternId = pId;
              demo.templateId = tId;
              // found it - stop looking
              return;
            }
          }
        }
      })();
    });
  },
} satisfies Migration;
