import { auth0 } from '@/services/auth0';
import { trackError } from './analytics';

export const getUserToken = async (): ReturnType<
  typeof auth0.getTokenSilently
> => {
  try {
    const token = await auth0.getTokenSilently();
    return token;
  } catch (e) {
    trackError(e);

    // Leaving the following as reference. No need to implement currently
    // if (e instanceof GenericError && e.error === 'login_required') {
    //   await loginWithRedirect();
    // }

    // Pass the error along so implementor can handle it
    throw e;
  }
};
