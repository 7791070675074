import * as Sentry from '@sentry/nextjs';

export const {
  addBreadcrumb,
  captureException,
  setUser,
  setContext,
  setTag,
  setTags,
} = Sentry;
