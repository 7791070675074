import { KsAppClientDataAndMeta } from '@knapsack/types';

export type AppClientMeta = {
  /**
   * Indicates if `knapsack start` or `knapsack serve` is running
   */
  mode: 'serve' | 'start';
  websocketsPort: number;
  version: string;
  knapsackCloudSiteId: string;
  ksVersions: {
    app: string;
  };
} & Pick<KsAppClientDataAndMeta['metaState'], 'plugins'>;

export function extractMetaState(
  metaState: KsAppClientDataAndMeta['metaState'],
): AppClientMeta {
  const {
    isLocalDev,
    plugins = [],
    meta: { websocketsPort, version, knapsackCloudSiteId, ksVersions } = {},
  } = metaState;
  return {
    // making more sense of what `isLocalDev` means
    // search for `isLocalDev` in the `@knapsack/app` package to see how it's set
    mode: isLocalDev ? 'start' : 'serve',
    plugins,
    websocketsPort,
    version,
    knapsackCloudSiteId,
    ksVersions,
  };
}
