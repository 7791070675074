import type {
  EndpointPaths,
  TemplateSuggestionsReqParams,
  TemplateSuggestionsResBody,
} from '@knapsack/app/api-info';
import { fetcher } from '@knapsack/utils';

export async function getTemplateSuggestions({
  appClientUrl,
  query,
}: {
  appClientUrl: string;
  query: TemplateSuggestionsReqParams;
}): Promise<TemplateSuggestionsResBody> {
  return fetcher<TemplateSuggestionsResBody>({
    url: new URL(
      '/api/v1/template-suggestions' satisfies EndpointPaths,
      appClientUrl,
    ).toString(),
    query,
  });
}
