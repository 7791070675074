import { ColorConfig } from '../color-config';

export enum PageHeaderSizes {
  auto = 'auto',
  small = 'small',
  medium = 'medium',
  large = 'large',
  full = 'full',
}

export interface PageHeaderSettings {
  size?: `${PageHeaderSizes}`;
  backgroundImage?: string;
  backgroundColor?: ColorConfig;
  backgroundOverlay?: boolean;
  backgroundSize?: 'cover' | 'contain' | 'string';
  backgroundPosition?: 'left' | 'center' | 'right' | 'string';
  backgroundRepeat?: 'no-repeat' | 'repeat-x' | 'repeat-y' | 'repeat';
  hideText?: boolean;
  textColorTitle?: ColorConfig;
  textColorDescription?: ColorConfig;
}
